import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { db, functions } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
  getDoc,
  getCountFromServer,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";
import { httpsCallable } from "firebase/functions";
import { SubscriptionType } from "./SubscriptionModule";

export enum AccountUpgradeStatus {
  registered = "registered",
  cancelled = "cancelled",
  rejected = "rejected",
  onVerify = "onVerify",
  onProgress = "onProgress",
  noRequest = "noRequest",
}

export enum AccountPageQuery {
  all = "all",
  admin = "admin",
  superteam = "superteam",
  appUser = "appUser",
  subscription = "subscription",
}

export enum ProfileRole {
  consumer = "Konsumen",
  farmer = "Petani",
  empty = "-",
}

export enum UserStatus {
  active = "active",
  suspended = "suspended",
}

export enum UserAuthorization {
  root = "root",
  admin = "admin",
  appUser = "appUser",
  superteam = "superteam",
}

interface AccountStoreState {
  doc: Record<string, AccountDoc>;
  index: { [key: string]: AccountIndex };
  indexUnsub: Unsubscribe | null;
  pageQuery: {
    [key in AccountPageQuery]: Array<string>;
  } & {
    lastDoc?: any;
    totalDocs?: number;
  };
  textQuery: { [key: string]: Array<string> };
  modal: { userEdit: { uid: string | null } };
  lastDoc?: any;
}

export interface AccountIndex {
  uid: string;
  create: Timestamp | null;

  email: string;
  name: string | null;
  photo: string;
  role: string | null;

  subs: string | null;
  status: string | null;
  expiry: Timestamp | null;
}

interface AccountDoc {
  user: UserDoc | null;
  profile: ProfileDoc | null;
  superteam?: SuperteamDoc | null;
}

interface UserDoc {
  id: string;
  data: UserDocData;
  unsub: Unsubscribe | null;
}

interface ProfileDoc {
  id: string;
  data: ProfileDocData;
  unsub: Unsubscribe | null;
}

interface SuperteamDoc {
  id: string;
  data: SuperteamDocData;
  unsub: Unsubscribe | null;
}

interface UserDocData {
  uid: string;
  email: string;
  registerDate: Timestamp | null;
  lastOnlineDate: Timestamp | null;
  authorization: UserAuthorization;
  purchase: {
    total: number;
    lastDate: Timestamp | null;
  };
  sale: {
    total: number;
    lastDate: Timestamp | null;
  };
  subscription: {
    type: SubscriptionType;
    endDate: Timestamp | null;
  };
  status: UserStatus;
  location: {
    geopoint: GeoPoint | null;
    address: string;
  };
}

interface ProfileDocData {
  uid: string;
  photoURL: string;
  name: string;
  role: ProfileRole;
  phone: string;
  address: string;

  upgrade: {
    lastUpdated: Timestamp | null;
    receiptImageURL: string;
    price: number;
    type: SubscriptionType;
    status: AccountUpgradeStatus;
    duration: {
      period: number;
      unit: string;
    };
  };
}

interface SuperteamDocData {
  uid: string;
  name: string;
  contact: {
    whatsapp: string;
  };
  location: {
    geopoint: GeoPoint | null;
    address: string;
  };
}

interface PageQuery {
  all: Array<string>;
  admin: Array<string>;
  superteam: Array<string>;
  appUser: Array<string>;
  subscription: Array<string>;
  lastDoc?: any;
  totalDocs?: number;
}

@Module
export default class AccountModule
  extends VuexModule
  implements AccountStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: AccountDoc };
  index = {} as { [key: string]: AccountIndex };
  indexUnsub = null as Unsubscribe | null;
  pageQuery = {
    all: [],
    admin: [],
    superteam: [],
    appUser: [],
    subscription: [],
    lastDoc: null,
  } as AccountStoreState["pageQuery"];
  textQuery = {} as { [key: string]: Array<string> };
  modal = { userEdit: { uid: null } } as AccountStoreState["modal"];
  lastDoc?: any;

  @Mutation
  [Mutations.ACCOUNT_SET_MODAL_USEREDIT](uid: string) {
    this.modal.userEdit.uid = uid ?? null;
  }

  @Mutation
  [Mutations.ACCOUNT_SET_INDEX](payload: { id: string; data: DocumentData }) {
    const id = payload.id;
    const data = payload.data;

    const docData = {
      uid: id,
      create: data.create ?? null,

      email: data.email ?? "",
      photo: data.photo ?? "",
      name: data.name ?? null,
      role: data.role ?? null,

      subs: data.subs ?? null,
      status: data.status ?? null,
      expiry: data.expiry ?? null,
    } as AccountIndex;

    if (id) this.index[id] = docData;
    // console.log(docData)
  }

  @Mutation
  [Mutations.ACCOUNT_SET_INDEX_SUB](payload: { unsub: Unsubscribe }) {
    const unsub = payload.unsub;
    this.indexUnsub = unsub ?? null;
  }

  @Mutation
  [Mutations.ACCOUNT_SET_USER_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      email: data.email ?? "",
      authorization: data.authorization ?? UserAuthorization.appUser,
      registerDate: data.registerDate ?? null,
      lastOnlineDate: data.lastOnlineDate ?? null,
      purchase: {
        total: data.purchase?.total ?? null,
        lastDate: data.purchase?.lastDate ?? null,
      },
      sale: {
        total: data.sale?.total ?? 0,
        lastDate: data.sale?.lastDate ?? null,
      },
      subscription: {
        type: data.subscription?.type ?? SubscriptionType.free,
        endDate: data.subscription?.endDate ?? null,
      },
      status: data.status ?? UserStatus.active,
      location: {
        geopoint: data.location?.geopoint ?? null,
        address: data.location?.address ?? "",
      },
    } as UserDocData;

    if (unsub) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      this.doc[id].user = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]?.user) {
      this.doc[id].user!.data = docData;
    } else {
      console.error(`FAILED SETTING USER DOC`);
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_PROFILE_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      photoURL:
        data.photoURL?.length ?? 0 > 0
          ? data.photoURL
          : data.imageURL?.length ?? 0 > 0
          ? data.imageURL
          : "",
      name: data.name ?? "",
      role: data.role ?? ProfileRole.empty,
      phone: data.phone ?? "",
      address: data.address ?? "",

      upgrade: {
        lastUpdated: data.upgrade?.lastUpdated ?? null,
        receiptImageURL: data.upgrade?.receiptImageURL ?? "",
        price: data.upgrade?.price ?? 0,
        type:
          data.upgrade?.type in SubscriptionType
            ? data.upgrade?.type
            : SubscriptionType.free,
        status:
          data.upgrade?.status in AccountUpgradeStatus
            ? data.upgrade?.status
            : AccountUpgradeStatus.noRequest,
        duration: {
          period: 6,
          unit: "month",
        },
      },
    } as ProfileDocData;

    if (unsub) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      this.doc[id].profile = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]?.profile) {
      this.doc[id].profile!.data = docData;
    } else {
      console.error(`FAILED SETTING PROFILE DOC`);
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_SUPERTEAM_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      name: data.name ?? "",
      contact: {
        whatsapp: data.contact?.whatsapp ?? "",
      },
      location: {
        geopoint: data.location?.geopoint ?? null,
        address: data.location?.address ?? "",
      },
    } as SuperteamDocData;

    if (unsub) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      this.doc[id].superteam = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]?.superteam) {
      this.doc[id].superteam!.data = docData;
    } else {
      console.error(`FAILED SETTING ACCOUNT SUPERTEAM DOC`);
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_PAGE_QUERY](payload: {
    key: AccountPageQuery;
    ids: Array<string>;
    append: boolean;
    lastDoc?: any;
    totalDocs?: number;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;
    const lastDoc = payload.lastDoc;
    const totalDocs = payload.totalDocs;

    // Initialize doc if it doesn't exist
    ids.forEach((id) => {
      if (!this.doc[id]) {
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      }
    });

    // Update pageQuery
    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }

    // Save reference to last document
    if (lastDoc) {
      this.pageQuery.lastDoc = lastDoc;
    }

    // Save total documents
    if (totalDocs !== undefined) {
      this.pageQuery.totalDocs = totalDocs;
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Mutation
  SET_PAGE_CACHE(payload: { page: number; lastDoc: any }) {
    // This mutation is to handle caching intermediate pagination documents
    // It will be called from the action but implemented in the component
    // No implementation needed here as it's handled by the component's ref
  }

  @Action
  async [Actions.ACCOUNT_POPULATE_PAGE_QUERY](payload: {
    key: AccountPageQuery;
    append: boolean;
    page?: number;
    startPage?: number;
    lastDoc?: any;
    useCache?: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;
    const page = payload.page || 1;
    const startPage = payload.startPage || 1;
    const lastDoc = payload.lastDoc;
    const useCache = payload.useCache || false;

    // Unsubscribe from existing listeners if not appending
    if (!append) {
      Object.values(this.doc).forEach((doc) => {
        doc.user?.unsub?.();
        doc.profile?.unsub?.();
        doc.superteam?.unsub?.();
      });
    }

    if (key === AccountPageQuery.appUser) {
      try {
        // Get total document count
        const countQuery = query(
          collection(db, "users"),
          where("versionNo", "==", 0),
          where("authorization", "==", UserAuthorization.appUser)
        );

        const totalSnapshot = await getCountFromServer(countQuery);
        const totalDocs = totalSnapshot.data().count;

        // Create base query
        let baseQuery = query(
          collection(db, "users"),
          where("versionNo", "==", 0),
          where("authorization", "==", UserAuthorization.appUser),
          orderBy("registerDate", "desc"),
          limit(20)
        );

        // If using cache, simply get documents after the lastDoc
        if (useCache && lastDoc) {
          baseQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            where("authorization", "==", UserAuthorization.appUser),
            orderBy("registerDate", "desc"),
            startAfter(lastDoc),
            limit(20)
          );
        }
        // If jumping pages
        else if (lastDoc && startPage < page) {
          const pagesToSkip = page - startPage;

          // For larger jumps, use a more efficient approach
          if (pagesToSkip > 10) {
            console.log(`Large page jump detected: ${startPage} to ${page}`);

            // For very large jumps, use a chunking approach
            const jumpSize = 5; // Adjust based on your needs (number of pages per chunk)
            let currentDoc = lastDoc;

            // Calculate how many chunks we need to jump
            const chunks = Math.floor(pagesToSkip / jumpSize);
            const remainingPages = pagesToSkip % jumpSize;

            console.log(
              `Jumping in ${chunks} chunks of ${jumpSize} pages, with ${remainingPages} remaining pages`
            );

            // Jump in chunks
            for (let i = 0; i < chunks; i++) {
              console.log(`Processing chunk ${i + 1}/${chunks}`);

              const chunkQuery = query(
                collection(db, "users"),
                where("versionNo", "==", 0),
                where("authorization", "==", UserAuthorization.appUser),
                orderBy("registerDate", "desc"),
                startAfter(currentDoc),
                limit(jumpSize * 20)
              );

              const jumpDocs = await getDocs(chunkQuery);
              if (jumpDocs.empty) {
                console.log("No more documents found during chunk jump");
                break;
              }

              currentDoc = jumpDocs.docs[jumpDocs.docs.length - 1];

              // Cache this position for future use
              const intermediatePageNum = startPage + (i + 1) * jumpSize;
              console.log(`Caching lastDoc for page ${intermediatePageNum}`);

              // We could dispatch this to the component, but we'll handle it there directly
              // this.context.commit('SET_PAGE_CACHE', {
              //   page: intermediatePageNum,
              //   lastDoc: currentDoc
              // });
            }

            // Handle remaining pages if any
            if (remainingPages > 0) {
              console.log(`Processing remaining ${remainingPages} pages`);

              const remainingQuery = query(
                collection(db, "users"),
                where("versionNo", "==", 0),
                where("authorization", "==", UserAuthorization.appUser),
                orderBy("registerDate", "desc"),
                startAfter(currentDoc),
                limit(remainingPages * 20)
              );

              const remainingDocs = await getDocs(remainingQuery);
              if (!remainingDocs.empty) {
                currentDoc = remainingDocs.docs[remainingDocs.docs.length - 1];
              } else {
                console.log(
                  "No more documents found during remaining pages jump"
                );
              }
            }

            // Final query to get the actual page data
            baseQuery = query(
              collection(db, "users"),
              where("versionNo", "==", 0),
              where("authorization", "==", UserAuthorization.appUser),
              orderBy("registerDate", "desc"),
              startAfter(currentDoc),
              limit(20)
            );
          } else {
            // For smaller jumps, use single query approach
            baseQuery = query(
              collection(db, "users"),
              where("versionNo", "==", 0),
              where("authorization", "==", UserAuthorization.appUser),
              orderBy("registerDate", "desc"),
              startAfter(lastDoc),
              limit(pagesToSkip * 20)
            );

            const jumpDocs = await getDocs(baseQuery);
            if (!jumpDocs.empty) {
              const lastVisible = jumpDocs.docs[jumpDocs.docs.length - 1];

              // Create final query to get the actual page data
              baseQuery = query(
                collection(db, "users"),
                where("versionNo", "==", 0),
                where("authorization", "==", UserAuthorization.appUser),
                orderBy("registerDate", "desc"),
                startAfter(lastVisible),
                limit(20)
              );
            }
          }
        } else if (lastDoc) {
          // If we have a lastDoc but not jumping pages, just use it
          baseQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            where("authorization", "==", UserAuthorization.appUser),
            orderBy("registerDate", "desc"),
            startAfter(lastDoc),
            limit(20)
          );
        }

        // Execute the final query
        const userDocs = await getDocs(baseQuery);
        const ids: Array<string> = [];
        const batchSize = 5;

        // Store the last document for future use
        const lastVisible = userDocs.docs[userDocs.docs.length - 1];

        // Process documents in batches
        for (let i = 0; i < userDocs.docs.length; i += batchSize) {
          const batch = userDocs.docs.slice(i, i + batchSize);

          await Promise.all(
            batch.map(async (userDoc) => {
              try {
                const profileDoc = await getDoc(
                  doc(collection(db, "profiles"), userDoc.id)
                );
                ids.push(userDoc.id);

                // Set up listeners with error handling
                if (!this.doc[userDoc.id]?.user) {
                  const unsub = onSnapshot(
                    doc(db, "users", userDoc.id),
                    (dbDoc) => {
                      this.context.commit(Mutations.ACCOUNT_SET_USER_DOC, {
                        id: dbDoc.id,
                        data: dbDoc.data() ?? {},
                        unsub: unsub,
                      });
                    },
                    (error) => {
                      console.error(
                        `Error in user listener for ${userDoc.id}:`,
                        error
                      );
                    }
                  );
                }

                if (!this.doc[userDoc.id]?.profile) {
                  const unsub = onSnapshot(
                    doc(db, "profiles", userDoc.id),
                    (dbDoc) => {
                      this.context.commit(Mutations.ACCOUNT_SET_PROFILE_DOC, {
                        id: dbDoc.id,
                        data: dbDoc.data() ?? {},
                        unsub: unsub,
                      });
                    },
                    (error) => {
                      console.error(
                        `Error in profile listener for ${userDoc.id}:`,
                        error
                      );
                    }
                  );
                }

                // Update index with user data
                this.context.commit(Mutations.ACCOUNT_SET_INDEX, {
                  id: userDoc.id,
                  data: {
                    uid: userDoc.id,
                    email: userDoc.data().email ?? "",
                    name:
                      profileDoc.data()?.name ??
                      userDoc.data().displayName ??
                      "",
                    create: userDoc.data().registerDate ?? null,
                    role: profileDoc.data()?.role ?? "Konsumen",
                    subs: userDoc.data().subscription?.type ?? null,
                    status: userDoc.data().status ?? "registered",
                    expiry: userDoc.data().subscription?.endDate ?? null,
                    photo: profileDoc.data()?.photoURL ?? "",
                  },
                });
              } catch (error) {
                console.error(
                  `Error processing document ${userDoc.id}:`,
                  error
                );
              }
            })
          );
        }

        this.context.commit(Mutations.ACCOUNT_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
          lastDoc: lastVisible,
          totalDocs: totalDocs,
        });

        return { lastDoc: lastVisible };
      } catch (error) {
        console.error("Error fetching app users:", error);
      }
    } else {
      let userQuery: Query<DocumentData>;
      switch (key) {
        case AccountPageQuery.admin:
          userQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            where("authorization", "==", UserAuthorization.admin),
            orderBy("registerDate", "desc")
          );
          break;
        case AccountPageQuery.superteam:
          userQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            where("authorization", "==", UserAuthorization.superteam),
            orderBy("registerDate", "desc")
          );
          break;
        case AccountPageQuery.subscription:
          userQuery = query(
            collection(db, "profiles"),
            where("versionNo", "==", 0),
            orderBy("upgrade.lastUpdated", "desc")
          );
          break;
        case AccountPageQuery.all:
        default:
          userQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            orderBy("registerDate", "desc")
          );
          break;
      }

      getDocs(userQuery)
        .then((userDocs) => {
          const ids: Array<string> = [];

          userDocs.forEach((userDoc) => {
            ids.push(userDoc.id);
            if (!this.doc[userDoc.id]?.user) {
              const unsub = onSnapshot(
                doc(db, "users", userDoc.id),
                (dbDoc) => {
                  this.context.commit(Mutations.ACCOUNT_SET_USER_DOC, {
                    id: dbDoc.id,
                    data: dbDoc.data() ?? {},
                    unsub: unsub,
                  });
                }
              );
            }
            if (!this.doc[userDoc.id]?.profile) {
              const unsub = onSnapshot(
                doc(db, "profiles", userDoc.id),
                (dbDoc) => {
                  this.context.commit(Mutations.ACCOUNT_SET_PROFILE_DOC, {
                    id: dbDoc.id,
                    data: dbDoc.data(),
                    unsub: unsub,
                  });
                }
              );
            }
            if (
              userDoc.data().authorization === UserAuthorization.superteam &&
              !this.doc[userDoc.id]?.superteam
            ) {
              const unsub = onSnapshot(
                doc(db, "superteams", userDoc.id),
                (dbDoc) => {
                  this.context.commit(Mutations.ACCOUNT_SET_SUPERTEAM_DOC, {
                    id: dbDoc.id,
                    data: dbDoc.data(),
                    unsub: unsub,
                  });
                }
              );
            }
          });

          this.context.commit(Mutations.ACCOUNT_SET_PAGE_QUERY, {
            key: key,
            ids: ids,
            append: append,
          });
        })
        .catch((error) => console.log("Error fetching data:", error.message));
    }
  }

  @Action({ rawError: true })
  [Actions.ACCOUNT_GET_SINGLE](payload: { uid: string; isSuperteam: boolean }) {
    console.log("ACCOUNT_GET_SINGLE", payload);
    const uid = payload.uid;
    const isSuperteam = payload.isSuperteam;
    if (!this.doc[uid]) {
      if (!this.doc[uid]) {
        const unsub = onSnapshot(doc(db, "users", uid), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_USER_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
      if (!this.doc[uid]) {
        const unsub = onSnapshot(doc(db, "profiles", uid), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_PROFILE_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
      if (isSuperteam && !this.doc[uid]) {
        const unsub = onSnapshot(doc(db, "superteams", uid), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_SUPERTEAM_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
    }
  }

  @Action({ rawError: true })
  [Actions.ACCOUNT_UPDATE_DOC](payload: {
    id: string;
    user: { [x: string]: any } | undefined;
    profile: { [x: string]: any } | undefined;
    superteam: { [x: string]: any } | undefined;
  }) {
    const id = payload.id;
    const user = payload.user;
    const profile = payload.profile;
    const superteam = payload.superteam;

    // Get a new write batch
    const batch = writeBatch(db);

    if (user) {
      // Set profile value
      const userRef = doc(db, "users", id);
      batch.update(userRef, user);
    }

    if (profile) {
      // Set profile value
      const profileRef = doc(db, "profiles", id);
      batch.update(profileRef, profile);
    }

    if (superteam) {
      // Set profile value
      const superteamRef = doc(db, "superteams", id);
      batch.update(superteamRef, superteam);
    }

    // Commit the batch
    return batch.commit();
  }

  @Action({ rawError: true })
  async [Actions.ACCOUNT_CREATE](payload: {
    id: string;
    email: string;
    password: string;
    user: DocumentData | undefined;
    profile: DocumentData | undefined;
    superteam: DocumentData | undefined;
  }) {
    const id = payload.id;
    const email = payload.email;
    const password = payload.password;

    const user = payload.user;
    const profile = payload.profile;
    const superteam = payload.superteam;

    const createUser = httpsCallable(functions, "createUser");
    await createUser({
      uid: id,
      email: email,
      password: password,
      name: profile?.name ?? undefined,
      photoURL: profile?.photoURL ?? undefined,
    }).then((result) => {
      console.log("cf result:", "createUser", result.data);
    });

    // Get a new write batch
    const batch = writeBatch(db);

    if (user) {
      // Set profile value
      const userRef = doc(db, "users", id);
      batch.set(userRef, {
        versionNo: 0,
        ...user,
      });
    }

    if (profile) {
      // Set profile value
      const profileRef = doc(db, "profiles", id);
      batch.set(profileRef, {
        versionNo: 0,
        ...profile,
      });
    }

    if (superteam) {
      // Set profile value
      const superteamRef = doc(db, "superteams", id);
      batch.set(superteamRef, {
        versionNo: 0,
        ...superteam,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export {
  AccountStoreState,
  AccountDoc,
  UserDocData,
  ProfileDocData,
  SuperteamDocData,
};
